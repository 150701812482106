<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 10:36:04
 * @LastEditTime: 2023-04-10 09:57:33
 * @Descripttion: 【作业考试-题库】试卷管理
-->
<style lang="scss" scoped>
.exam-papers {
    @include innerPage;
    @include pageHead(-10px);
    @include pageFoot;

    .page-head {
        height: 224px;
        flex-wrap: wrap;
        padding-top: 0;

        .head-wrapper.column {
            margin-top: 12px;
        }

        .page-tab .data-sort {
            margin-left: auto;
            position: relative;
            @include flexBox;

            li {
                margin: 0 60px;
                cursor: pointer;
                color: #1f1f1f;
                @include flexBox(center);

                .sort {
                    flex-direction: column;
                    @include flexBox(center);

                    i {
                        color: #a8a8a8;
                        font-size: 12px;

                        &:first-child {
                            margin-bottom: -6px;
                        }
                    }
                }

                &.asc i:first-child {
                    color: #6340c8;
                }

                &.desc i:last-child {
                    color: #6340c8;
                }
            }

            &::after {
                content: '';
                width: 1px;
                height: 20px;
                background: rgba($color: #7a7a7a, $alpha: 0.24);
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -0.5px;
                margin-top: -10px;
            }
        }

        .single-search {
            width: 90.64%;

            .el-button:not(.search) {
                margin-left: 10px;
            }
        }
    }

    .page-inner {
        width: 100%;
        height: calc(100% - 262px);
        margin-top: 38px;

        .paper {
            &-wrapper {
                width: calc(100% + 24px);
                height: calc(100% - 74px);
                margin-left: -36px;
                box-sizing: border-box;
                padding-bottom: 30px;

                &.no-data {
                    width: calc(100% - 24px);
                    height: calc(100% - 104px);
                    background: #fff;
                    border-radius: 10px;
                    margin: 0 auto 30px;
                }
            }

            &-list {
                width: calc(100% + 20px);
                height: 100%;
                overflow: hidden;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 0 20px;
                flex-wrap: wrap;
                @include flexBox(flex-start, flex-start);

                &.not-mine .paper-item h4::after {
                    display: none;
                }

                .paper-item {
                    width: calc((100% - 72px) / 3);
                    height: 266px;
                    border-radius: 10px;
                    background: #fff;
                    margin-left: 24px;
                    margin-bottom: 28px;
                    overflow: hidden;

                    &.is-mine {
                        h4::after {
                            content: '未共享';
                            width: 62px;
                            height: 30px;
                            line-height: 30px;
                            box-sizing: border-box;
                            padding-left: 10px;
                            border-radius: 0 0 0 20px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            font-family: 'MicrosoftYaHei';
                            font-size: 14px;
                            color: #fff;
                            background: #a8a8a8;
                        }

                        &.reviewed h4:after {
                            content: '待审核';
                            background: #feaf2f;
                        }

                        &.failed h4:after {
                            content: '未通过';
                            background: #ee4f65;
                        }

                        &.shared h4:after {
                            content: '已共享';
                            background: #2ac293;
                        }

                        .el-button {
                            padding: 0;
                        }
                    }

                    h4 {
                        width: 100%;
                        height: 60px;
                        background: #f6f7fc;
                        box-sizing: border-box;
                        padding-left: 58px;
                        padding-right: 40px;
                        color: #474747;
                        flex-shrink: 0;
                        position: relative;
                        @include flexBox;

                        span {
                            display: inline-block;
                            width: 100%;
                        }

                        &::before {
                            content: '\e631';
                            font-family: 'iconfont';
                            font-size: 30px;
                            color: #d0cbe7;
                            position: absolute;
                            left: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .info-content {
                        width: 100%;
                        height: 146px;
                        @include flexBox;

                        .q_number,
                        .s_number {
                            width: 50%;
                            text-align: center;
                            font-size: 16px;
                            color: #2a2a2a;
                            line-height: 36px;
                            position: relative;

                            .pf_bold {
                                font-size: 24px;
                                color: #393939;
                            }
                        }

                        .s_number::before {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background: #a3a3a3;
                            position: absolute;
                            bottom: 12px;
                            left: 0;
                        }
                    }

                    .info-bt {
                        width: calc(100% - 76px);
                        height: 58px;
                        margin: 0 auto;
                        border-top: 1px solid #e9e9e9;
                        @include flexBox(space-between);

                        .el-button {
                            margin-left: 10px;
                            flex-shrink: 0;

                            &:hover {

                                i,
                                span {
                                    color: #6c4bcb;
                                }
                            }
                        }

                        .paper-from {
                            min-width: 80px;
                            height: 32px;
                            border-radius: 16px;
                            background: rgba($color: #a8a8a8, $alpha: .26);
                            text-align: center;
                            line-height: 32px;
                            color: #8a8a8a;
                            box-sizing: border-box;
                            padding: 0 8px;
                        }

                        .paper-info {
                            color: #696969;
                            @include flexBox;

                            .iconfont {
                                font-size: 18px;
                            }

                            .from {
                                margin-right: 8px;
                            }

                            &.shared {
                                color: #6c4bcb;
                                cursor: default;
                            }
                        }

                        .share-person {
                            color: #aeaeae;
                            margin-left: auto;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.24), 5px 5px 27px -10px rgba(108, 78, 203, 0.24);
                    }
                }
            }
        }
    }
}
</style>

<template>
    <section class="exam-papers">
        <div class="page-head">
            <div class="page-head--inner">
                <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
                <div class="head-wrapper column">
                    <breadcrumb />
                    <div class="wrap-plate">
                        <div class="single-search">
                            <div class="search-ipt">
                                <div class="grade-select">
                                    <el-dropdown placement="bottom-start" trigger="click"
                                        @visible-change="dropdownVisible = !dropdownVisible">
                                        <p class="select-result el-dropdown-link">
                                            <span>{{ searchForm.grade.grade_name }}</span>
                                            <i class="iconfont" :class="{ revolve: dropdownVisible }">&#xe635;</i>
                                        </p>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :class="{ current: searchForm.grade.grade_id == -1 }"
                                                @click.native="changeGrade({ grade_id: -1, grade_name: '全部' })">全部</el-dropdown-item>
                                            <el-dropdown-item
                                                :class="{ current: searchForm.grade.grade_id == item.grade_id }"
                                                v-for="item in gradeList" :key="item.grade_id"
                                                @click.native="changeGrade(item)">
                                                {{ item.grade_name }}
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="搜索试卷名称"
                                    clearable>
                                    <span slot="prefix" class="iconfont">&#xe61c;</span>
                                </el-input>
                            </div>
                            <el-button type="custom_primary search" size="medium" @click="searchData">搜索</el-button>
                            <el-button type="custom_warning" size="medium" v-if="currentTab == 1"
                                @click="$router.push({ name: 'HE_EXAMPAPERADD' })">
                                新建+
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-tab">
                <ul class="tabs bold">
                    <li class="tab" :class="{ current: currentTab == tab.key }" v-for="tab in tabs" :key="tab.key"
                        @click="changeTab(tab.key)">
                        {{ tab.name }}
                    </li>
                </ul>
                <ul class="data-sort">
                    <li :class="{ asc: searchForm.usenum == 'asc', desc: searchForm.usenum == 'desc' }"
                        @click="changeSort('usenum')">
                        <span>使用频次</span>
                        <span class="sort">
                            <i class="iconfont">&#xe636;</i><i class="iconfont">&#xe635;</i>
                        </span>
                    </li>
                    <li :class="{ asc: searchForm.create_time == 'asc', desc: searchForm.create_time == 'desc' }"
                        @click="changeSort('create_time')">
                        <span>创建时间</span>
                        <span class="sort">
                            <i class="iconfont">&#xe636;</i><i class="iconfont">&#xe635;</i>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="page-inner">
            <div class="paper-wrapper" :class="{ 'no-data': $isEmpty(listData) }">
                <ul class="paper-list" :class="{ 'not-mine': currentTab != 1 }" v-show="!$isEmpty(listData)">
                    <template v-if="currentTab == 1">
                        <li class="paper-item" @click="targetDetail(paper.tepap_id)"
                            :class="[paper.tepap_type == 10 && userInfo.enter == 1 && 'is-mine', myPaperShare(paper.tepap_isshare)]"
                            v-for="paper in listData" :key="paper.tepap_id">
                            <h4 class="bold">
                                <span class="line-text--1st">{{ paper.tepap_title }}</span>
                            </h4>
                            <div class="info-content">
                                <div class="q_number">
                                    <p class="pf_bold">{{ paper.tepap_sumquestion || 0 }}</p>
                                    <p>共计题目数（题）</p>
                                </div>
                                <div class="s_number">
                                    <p class="pf_bold">{{ paper.tepap_sumscore || 0 }}</p>
                                    <p>总分（分）</p>
                                </div>
                            </div>
                            <div class="info-bt">
                                <p class="paper-from"> {{ paper.tepap_type == 10 && '我的试卷' || paper.tepap_type == 20 &&
                                    '校本试卷' ||
                                    paper.tepap_type == 30 && '学校共享试卷' }}
                                </p>
                                <p class="share-person" v-if="paper.tepap_isshare == 20">共享人：{{ paper.teuse_name }}</p>
                                <template v-if="paper.tepap_type == 10">
                                    <el-button type="text"
                                        v-if="paper.tepap_isshare == 10 && userInfo.enter == 1 || paper.tepap_isshare == 30 && userInfo.enter == 1"
                                        @click.stop="sharePaper(paper.tepap_id)">
                                        <p class="paper-info">
                                            <i class="iconfont">&#xe639;</i>
                                            <span>{{ paper.tepap_isshare == 30 && '重新' || '' }}共享</span>
                                        </p>
                                    </el-button>
                                </template>
                            </div>
                        </li>
                    </template>
                    <template v-if="currentTab == 2">
                        <li class="paper-item" @click="targetDetail(paper.scpap_id)" v-for="paper in listData"
                            :key="paper.scpap_id">
                            <h4 class="bold">
                                <span class="line-text--1st">{{ paper.scpap_title }}</span>
                            </h4>
                            <div class="info-content">
                                <div class="q_number">
                                    <p class="pf_bold">{{ paper.scpap_sumquestion || 0 }}</p>
                                    <p>共计题目数（题）</p>
                                </div>
                                <div class="s_number">
                                    <p class="pf_bold">{{ paper.scpap_sumscore || 0 }}</p>
                                    <p>总分（分）</p>
                                </div>
                            </div>
                            <div class="info-bt">
                                <p class="paper-from">校本试卷</p>
                                <p class="share-person" v-if="paper.teuse_name">共享人：{{ paper.teuse_name }}</p>
                                <el-button v-if="paper.is_addmypaper != 1" type="custom_primary" size="small"
                                    @click.stop="joinMine(paper.scpap_id)">
                                    + 加入我的
                                </el-button>
                                <el-button v-else disabled type="custom_primary" size="small">
                                    已加入
                                </el-button>
                            </div>
                        </li>
                    </template>
                    <template v-if="currentTab == 3">
                        <li class="paper-item" @click="targetDetail(paper.plpap_id)" v-for="paper in listData"
                            :key="paper.plpap_id">
                            <h4 class="bold">
                                <span class="line-text--1st">{{ paper.plpap_title }}</span>
                            </h4>
                            <div class="info-content">
                                <div class="q_number">
                                    <p class="pf_bold">{{ paper.plpap_sumquestion || 0 }}</p>
                                    <p>共计题目数（题）</p>
                                </div>
                                <div class="s_number">
                                    <p class="pf_bold">{{ paper.plpap_sumscore || 0 }}</p>
                                    <p>总分（分）</p>
                                </div>
                            </div>
                            <div class="info-bt">
                                <p class="paper-from">学校共享试卷</p>
                                <p class="share-person" v-if="paper.teuse_name">共享人：{{ paper.teuse_name }}</p>
                                <el-button v-if="paper.is_addmypaper != 1" type="custom_primary" size="small"
                                    @click.stop="joinMine(paper.plpap_id)">
                                    + 加入我的
                                </el-button>
                                <el-button v-else disabled type="custom_primary" size="small">
                                    已加入
                                </el-button>
                            </div>
                        </li>
                    </template>
                </ul>
                <div class="no-data--empty" v-if="$isEmpty(listData)">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无试题数据哦~</p>
                </div>
            </div>
            <!-- 分页控件 -->
            <div class="custom-foot">
                <customPagination :current="listPage.pageIndex" :total="listPage.total" :size="listPage.pageSize" @pageChange="flippingPage" />
            </div>
        </div>
    </section>
</template>

<script>
import { $exampaperList, $shareExampaper, $exampaperInsert } from "@api/homeworkExam"
import { mapState } from "vuex";
import customPagination from "@comp/customPagination";
export default {
    name: 'he_examPapers',
    components: { customPagination },
    computed: {
        ...mapState("common", ["gradeList"]),
        ...mapState("user", ["userInfo"]),
        myPaperShare() {
            return function (val) {
                let className = '';
                switch (val) {
                    case 15:
                        className = 'reviewed'
                        break;
                    case 20:
                        className = 'shared'
                        break;
                    case 30:
                        className = 'failed'
                        break;
                    default:
                        break;
                }
                return className;
            }
        }
    },
    data() {
        return {
            tabs: [{
                name: '我的试卷',
                key: 1,
            }, {
                name: '校本试卷',
                key: 2,
            }, {
                name: '学校共享试卷',
                key: 3,
            }],
            currentTab: 1,
            searchForm: {
                grade: {
                    grade_id: -1,
                    grade_name: '全部'
                }
            },
            dropdownVisible: false, // 年级筛选下拉展开
            listData: [], // 考试列表数据
            /* 列表分页参数 */
            listPage: {
                pageIndex: 1,
                pageSize: 9,
                total: 0,
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            let params = {
                ...this.searchForm,
                grade: this.searchForm.grade.grade_id,
                pageindex: this.listPage.pageIndex,
            };
            if (params.grade == -1) delete params.grade;
            let { data: res } = await $exampaperList(this.currentTab, params, this.listPage.pageSize);
            this.listPage.total = res.allcount;
            this.listData = res.data;
            this.$forceUpdate();
        },
        /** 翻页 */
        flippingPage(val) {
            this.listData = [];
            this.listPage.pageIndex = val;
            this.getData();
        },
        /**
         * tab切换
         * @param {number} tab tab key值
         */
        changeTab(tab) {
            this.currentTab = tab;
            this.searchForm = {
                grade: {
                    grade_id: -1,
                    grade_name: '全部'
                }
            };
            this.searchData();
        },
        /**
         * 切换年级
         * @param {object} data 年级数据
         */
        changeGrade(data) {
            this.searchForm = {
                ...this.searchForm,
                grade: data
            }
            this.searchData();
        },
        /**
         * 切换数据排序
         * @param {string} el 排序根据
         */
        changeSort(el) {
            if (!this.searchForm[el]) {
                this.searchForm[el] = 'asc'
            } else {
                if (this.searchForm[el] == 'asc') this.searchForm[el] = 'desc';
                else delete this.searchForm[el]
            }
            this.$forceUpdate();
            this.getData();
        },
        /** 筛选数据 */
        searchData() {
            this.listData = [];
            this.listPage.pageIndex = 1;
            this.getData();
        },
        /**
         * 共享试卷
         * @param {number} id 试卷id
         */
        sharePaper(id) {
            this.$msgbox({
                title: "提示",
                message: "确认将选中试卷共享至学校？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $shareExampaper(id);
                if (res) {
                    this.$message.success('试卷共享申请已提交，等待学校审核');
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /**
         * 加入我的试卷
         * @param {number} id 试卷id
         */
        joinMine(id) {
            this.$msgbox({
                title: "提示",
                message: "确认将选中试卷加入我的？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $exampaperInsert(this.currentTab - 1, id);
                if (res) {
                    this.$message.success('试卷已成功加入我的试卷库');
                    this.getData();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
        /**
         * 跳转试卷详情
         * @param {number} 试卷id
         */
        targetDetail(id) {
            this.$router.push({
                name: 'HE_EXAMPAPERDETAIL',
                params: { id, type: this.currentTab }
            })
        }
    },
}
</script>